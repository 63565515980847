import icon from '../images/Home-Icon.svg';
import Content from '../content/Footer.json';
import styles from './footer-home-button.module.css';

interface FooterHomeButtonProps {
  lang: 'en' | 'ar',
  onClick: (() => void)
}

export default function FooterHomeButton ({ lang, onClick }: FooterHomeButtonProps) {
  let containerStyle = styles.container;
  let textStyle = styles.text;

  if (lang === 'ar') {
    containerStyle = styles.containerAr;
    textStyle = styles.textAr;
  }

  return (
    <div className={containerStyle} onClick={onClick}>
      <img src={icon} className={styles.icon} alt="" />
      <span className={textStyle}>{Content[lang].home}</span>
    </div>
  );
}
