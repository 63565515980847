import correctIcon from '../images/Correct-Icon.svg';
import almostIcon from '../images/Almost-Icon.svg';
import styles from './question-answer-button.module.css';
import { useEffect, useReducer } from 'react';

interface QuestionAnswerButtonProps {
  text: string,
  icon?: string,
  number: number,
  responseCorrect?: string | string[],
  responseIncorrect?: string | string[],
  answerGiven: null | number,
  setAnswerGiven: React.Dispatch<React.SetStateAction<null | number>>,
  onNext?: (() => void),
  lang: 'en' | 'ar'
}

const AUTO_ADVANCE_MS = 2000;

export default function QuestionAnswerButton ({
  text,
  icon: iconSource,
  number,
  responseCorrect,
  responseIncorrect,
  answerGiven,
  setAnswerGiven,
  onNext,
  lang
}: QuestionAnswerButtonProps) {
  const [ , setAdvanceTimeout ] = useReducer(
    (prevState: NodeJS.Timeout | null, newState: NodeJS.Timeout | null) => {
      if (prevState) clearTimeout(prevState);

      return newState;
    },
    null
  );

  useEffect(() => {
    if (answerGiven !== number && responseCorrect) setAdvanceTimeout(null);
  }, [ number, responseCorrect, answerGiven ]);

  useEffect(() => {
    if (!onNext) return;
    if (answerGiven === number && responseCorrect) {
      setAdvanceTimeout(setTimeout(onNext, AUTO_ADVANCE_MS));
    }
  }, [ onNext, answerGiven, number, responseCorrect ]);

  let feedbackIconStyle = styles.feedbackIcon;
  if (lang === 'ar') {
    feedbackIconStyle = styles.feedbackIconAr;
  }

  if (answerGiven !== number) {
    let icon = !iconSource ? null : (
      <div className={styles.answerIconWrapper}>
        <img src={`/assets/${iconSource}`} className={styles.answerIcon} alt="" />
      </div>
    );

    return (
      <div
        className={iconSource ? styles.containerWithIcon : styles.container}
        onClick={() => setAnswerGiven(number)}
        style={{ direction: lang === 'ar' ? 'rtl' : undefined }}
      >
        {icon}
        <div className={styles.text}>{text}</div>
      </div>
    );
  }

  if (answerGiven === number && responseCorrect) {
    let feedbackText = null;

    if (Array.isArray(responseCorrect)) {
      feedbackText = (
        <div className={styles.feedbackTextCorrect}>
          <span className={styles.feedbackTextLarge}>{responseCorrect[0]}</span>
          <span className={styles.feedbackTextSmall}>{responseCorrect[1]}</span>
        </div>
      );
    } else {
      feedbackText = (
        <div className={styles.feedbackTextCorrect}>
          <span className={styles.feedbackTextLarge}>{responseCorrect}</span>
        </div>
      );
    }

    return (
      <div
        className={iconSource ? styles.containerCorrectWithIcon : styles.containerCorrect}
        style={{ direction: lang === 'ar' ? 'rtl' : undefined }}
      >
        <div className={styles.feedback}>
          <img src={correctIcon} className={feedbackIconStyle} alt=""/>
          {feedbackText}
        </div>
        <div className={styles.text}>{text}</div>
      </div>
    );
  }

  if (answerGiven === number && responseIncorrect) {
    let feedbackText = null;

    if (Array.isArray(responseIncorrect)) {
      feedbackText = (
        <div className={styles.feedbackTextIncorrect}>
          <span className={styles.feedbackTextLarge}>{responseIncorrect[0]}</span>
          <span className={styles.feedbackTextSmall}>{responseIncorrect[1]}</span>
        </div>
      );
    } else {
      feedbackText = (
        <div className={styles.feedbackTextIncorrect}>
          <span className={styles.feedbackTextLarge}>{responseIncorrect}</span>
        </div>
      );
    }

    return (
      <div
        className={iconSource ? styles.containerIncorrectWithIcon : styles.containerIncorrect}
        style={{ direction: lang === 'ar' ? 'rtl' : undefined }}
      >
        <div className={styles.feedback}>
          <img src={almostIcon} className={feedbackIconStyle} alt="" />
          {feedbackText}
        </div>
        <div className={styles.text}>{text}</div>
      </div>
    );
  }

  throw new Error(`Invalid configuration for QuestionAnswerButton ${number}: "${text}"`);
}
