import FooterHomeButton from './footer-home-button';
import FooterBackButton from './footer-back-button';
import FooterProgressBar from './footer-progress-bar';

import footerLogo from '../images/Footer-Logo.svg';
import styles from './footer.module.css';

interface FooterProps {
  lang: 'en' | 'ar',
  onHome?: (() => void),
  onBack?: (() => void),
  progress?: null | number
}

export default function Footer ({
  lang,
  onHome,
  onBack,
  progress
}: FooterProps) {
  const homeButton = !onHome ? null : (
    <FooterHomeButton lang={lang} onClick={onHome} />
  );

  const backButton = !onBack ? null : (
    <FooterBackButton lang={lang} onClick={onBack} />
  );

  let progressBar = null;
  if (typeof progress == 'number') {
    progressBar = ( <FooterProgressBar progress={progress} /> );
  }

  return (
    <div className={styles.container}>
      {progressBar}
      <img src={footerLogo} className={styles.footerLogo} alt="" />
      <div className={styles.buttonsContainer}>
        {backButton}
        {homeButton}
      </div>
    </div>
  );
}
