import img0 from '../images/Progress-Vertical-0.svg';
import img100 from '../images/Progress-Vertical-100.svg';
import img0Alt from '../images/Progress-Vertical-Alt-0.svg';
import img100Alt from '../images/Progress-Vertical-Alt-100.svg';
import styles from './education-progress-bar.module.css';

import Settings from '../content/Settings.json';

interface EducationProgressBarProps {
  progress: number
}

export default function EducationProgressBar ({
  progress
}: EducationProgressBarProps) {
  return (
    <div className={styles.container}>
      <img
        src={Settings.altColor ? img0Alt : img0}
        className={styles.background}
        alt=""
      />
      <img
        src={Settings.altColor ? img100Alt : img100}
        className={styles.foreground}
        style={{ height: `${progress*100}%` }}
        alt=""
      />
    </div>
  );
}
