import icon from '../images/Back-Icon.svg';
import Content from '../content/Footer.json';
import styles from './footer-back-button.module.css';

interface FooterBackButtonProps {
  lang: 'en' | 'ar',
  onClick: (() => void)
}

export default function FooterBackButton ({ lang, onClick }: FooterBackButtonProps) {
  let containerStyle = styles.container;
  let textStyle = styles.text;
  let iconStyle = styles.icon;

  if (lang === 'ar') {
    containerStyle = styles.containerAr;
    textStyle = styles.textAr;
    iconStyle = styles.iconAr;
  }

  return (
    <div className={containerStyle} onClick={onClick}>
      <img src={icon} className={iconStyle} alt="" />
      <span className={textStyle}>{Content[lang].back}</span>
    </div>
  );
}
