import styles from './education.module.css';
import nextButtonIcon from '../images/Next-Button.svg';

import Footer from '../components/footer';
import EducationProgressBar from '../components/education-progress-bar';
import _Content from '../content/Education.json';
import Settings from '../content/Settings.json';

interface  EducationContentData {
  contentSide: 'left' | 'right',
  progress: number,
  image?: string,
  video?: string,
  en: {
    heading: string,
    paragraph: string
  },
  ar: {
    heading: string,
    paragraph: string
  }
}

const Content = _Content as Record<string,  EducationContentData>;

interface EducationProps {
  id: number
  lang: 'en' | 'ar'
  onHome: (() => void)
  onBack: (() => void)
  onNext: (() => void)
}

export default function Education ({
  id,
  lang,
  onHome,
  onBack,
  onNext
}: EducationProps) {
  const content = Content[id.toString()]
  const langContent = content[lang]

  let imageOrVideo = null;

  if (content.video) {
    imageOrVideo = (
      <video
        className={styles.imageOrVideoActual}
        src={`/assets/${content.video}`}
        autoPlay={true}
        controls={false}
        loop={true}
        playsInline={true}
        muted={true}
        crossOrigin='anonymous'
      />
    );
  } else if (content.image) {
    imageOrVideo = (
      <img src={`/assets/${content.image}`} alt="" className={styles.imageOrVideoActual}/>
    );
  }

  let headingStyle = styles.heading;
  let paragraphStyle = styles.paragraph;
  if (Settings.altColor) {
    headingStyle = styles.headingAltColor;
    paragraphStyle = styles.paragraphAltColor;
  }

  const textContainer = (
    <div
      className={styles.textContainer}
      style={{ direction: lang === 'ar' ? 'rtl' : undefined}}
    >
      <span className={headingStyle}>{langContent.heading}</span>
      <span className={paragraphStyle}>{langContent.paragraph}</span>
    </div>
  );

  const progressContainer = (
    <div className={styles.progressContainer}>
      <EducationProgressBar progress={content.progress} />
    </div>
  );

  let nextButtonStyle = styles.nextButtonContentLeft;
  let nextIconStyle = styles.nextIcon;

  if (content.contentSide === 'right') {
    nextButtonStyle = styles.nextButtonContentRight;
  }

  if (lang === 'ar') {
    nextIconStyle = styles.nextIconAr;
  }

  const imageOrVideoContainer = (
    <div className={styles.imageOrVideo}>
      {imageOrVideo}
      <div className={nextButtonStyle} onClick={onNext}>
        <img src={nextButtonIcon} className={nextIconStyle} alt="" />
      </div>
    </div>
  );

  const mainRendered = content.contentSide === 'right' ? (
    <>
      {imageOrVideoContainer}
      {progressContainer}
      {textContainer}
    </>
  ) : (
    <>
      {textContainer}
      {progressContainer}
      {imageOrVideoContainer}
    </>
  )

  let containerStyle = styles.container;
  if (Settings.altColor) containerStyle = styles.containerAltColor;

  return (
    <div className={containerStyle}>
      {mainRendered}
      <Footer lang={lang} onHome={onHome} onBack={onBack}/>
    </div>
  );
}
