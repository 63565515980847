import correctIcon from '../images/Correct-Icon.svg';
import almostIcon from '../images/Almost-Icon.svg';
import styles from './question-answer-image.module.css';

import { useCallback, useEffect, useMemo, useReducer } from "react";

interface QuestionAnswerImageProps {
  image: string,
  number: number,
  responseCorrect?: string | string[],
  responseIncorrect?: string | string[],
  answerGiven: null | number,
  setAnswerGiven: React.Dispatch<React.SetStateAction<null | number>>,
  onNext?: (() => void),
  lang: 'en' | 'ar'
}

const AUTO_ADVANCE_MS = 2000;

export default function QuestionAnswerImage ({
  image: imageSource,
  number,
  responseCorrect,
  responseIncorrect,
  answerGiven,
  setAnswerGiven,
  onNext,
  lang
}: QuestionAnswerImageProps) {
  const image = useMemo(() => (
    <img src={`/assets/${imageSource}`} className={styles.answerImage} alt="" />
  ), [ imageSource ]);

  let onClick = useCallback(() => {
    if (answerGiven !== number) {
      setAnswerGiven(number);
    }
  }, [ setAnswerGiven, answerGiven, number ]);

  const [ , setAdvanceTimeout ] = useReducer(
    (prevState: NodeJS.Timeout | null, newState: NodeJS.Timeout | null) => {
      if (prevState) clearTimeout(prevState);

      return newState;
    },
    null
  );

  useEffect(() => {
    if (answerGiven !== number && responseCorrect) setAdvanceTimeout(null);
  }, [ number, responseCorrect, answerGiven ]);

  useEffect(() => {
    if (!onNext) return;
    if (answerGiven === number && responseCorrect) {
      setAdvanceTimeout(setTimeout(onNext, AUTO_ADVANCE_MS));
    }
  }, [ onNext, answerGiven, number, responseCorrect ]);

  let feedback = null;

  let feedbackIconStyle = styles.feedbackIcon;
  if (lang === 'ar') {
    feedbackIconStyle = styles.feedbackIconAr;
  }

  let containerStyle = styles.container;

  if (answerGiven === number && responseCorrect) {
    containerStyle = styles.containerCorrect;

    let feedbackText = null;

    if (Array.isArray(responseCorrect)) {
      feedbackText = (
        <div className={styles.feedbackTextCorrect}>
          <span className={styles.feedbackTextLarge}>{responseCorrect[0]}</span>
          <span className={styles.feedbackTextSmall}>{responseCorrect[1]}</span>
        </div>
      );
    } else {
      feedbackText = (
        <div className={styles.feedbackTextCorrect}>
          <span className={styles.feedbackTextLarge}>{responseCorrect}</span>
        </div>
      );
    }

    feedback = (
      <div className={styles.feedbackCorrect}>
        <img src={correctIcon} className={feedbackIconStyle} alt=""/>
        {feedbackText}
      </div>
    );
  }

  if (answerGiven === number && responseIncorrect) {
    containerStyle = styles.containerIncorrect;

    let feedbackText = null;

    if (Array.isArray(responseIncorrect)) {
      feedbackText = (
        <div className={styles.feedbackTextIncorrect}>
          <span className={styles.feedbackTextLarge}>{responseIncorrect[0]}</span>
          <span className={styles.feedbackTextSmall}>{responseIncorrect[1]}</span>
        </div>
      );
    } else {
      feedbackText = (
        <div className={styles.feedbackTextIncorrect}>
          <span className={styles.feedbackTextLarge}>{responseIncorrect}</span>
        </div>
      );
    }

    feedback = (
      <div className={styles.feedbackIncorrect}>
        <img src={almostIcon} className={feedbackIconStyle} alt="" />
        {feedbackText}
      </div>
    );
  }

  return (
    <div
      className={containerStyle}
      onClick={onClick}
      style={{ direction: lang === 'ar' ? 'rtl' : undefined }}
    >
      {feedback}
      {image}
    </div>
  );
}
