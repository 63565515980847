import beginIcon from '../images/Begin-Icon.svg';
import styles from './quiz.module.css';

import Footer from '../components/footer';
import Content from '../content/Quiz.json';

interface QuizProps {
  lang: 'en' | 'ar'
  onHome: (() => void)
  onBack: (() => void)
  onNext: (() => void)
}

export default function Quiz ({
  lang,
  onHome,
  onBack,
  onNext
}: QuizProps) {
  const content = Content[lang]

  let buttonStyle = styles.button;
  let beginIconStyle = styles.beginIcon;

  if (lang === 'ar') {
    buttonStyle = styles.buttonAr;
    beginIconStyle = styles.beginIconAr;
  }

  return (
    <div className={styles.container}>
      <img
        className={styles.background}
        src={`/assets/${Content.backgroundImage}`}
        alt=""
      />
      <div className={styles.textContainer}>
        <span className={styles.heading}>{content.heading}</span>
      </div>
      <div className={styles.buttonsContainer}>
        <div
          className={buttonStyle}
          onClick={onNext}
        >
          <span className={styles.buttonText}>{content.go}</span>
          <img src={beginIcon} className={beginIconStyle} alt="" />
        </div>
      </div>
      <Footer lang={lang} progress={0} onHome={onHome} onBack={onBack} />
    </div>
  );
}
