import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import styles from './app.module.css';
import Home from './screens/home';
import Education from './screens/education';
import Quiz from './screens/quiz';
import Question from './screens/question';
import Complete from './screens/complete';

import Settings from './content/Settings.json';
import EducationContent from './content/Education.json';
import QuestionContent from './content/Questions.json';
import FullscreenActivator from './components/fullscreen-activator';

const EDUCATION_IDS = Array.from(Object.keys(EducationContent)).map(k => parseInt(k))
EDUCATION_IDS.sort();
const QUESTION_IDS = Array.from(Object.keys(QuestionContent)).map(k => parseInt(k))
QUESTION_IDS.sort();

const LAST_EDUCATION_ID = EDUCATION_IDS[EDUCATION_IDS.length - 1];
const LAST_QUESTION_ID = QUESTION_IDS[QUESTION_IDS.length - 1];

type ActiveScreenName = 'home' | 'education' | 'quiz' | 'question' | 'complete';
type ActiveScreen = [ ActiveScreenName, null | number ];

const LOCATION_QUERY = new URLSearchParams(window.location.search);

export default function App() {
  const [ activeScreen, setActiveScreen ] = useState<ActiveScreen>([
    LOCATION_QUERY.get('screen') as ActiveScreenName || 'home',
    LOCATION_QUERY.get('id') ? parseInt(LOCATION_QUERY.get('id') ?? '') : null
  ]);
  const [ lang, setLang ] = useState<'en' |'ar'>(LOCATION_QUERY.get('lang') as 'en' | 'ar' || 'en');

  useEffect(() => {
    const dpr = window.devicePixelRatio;

    document.documentElement.style.fontSize = `${16/dpr}px`;
  }, []);

  const [ , resetTimeout ] = useReducer(
    (prevState: NodeJS.Timeout | null, newState: boolean) => {
      if (prevState) clearTimeout(prevState);

      if (newState === true) {
        console.debug('resetTimeout(true)');

        return setTimeout(() => {
          console.warn('RESET');
          setActiveScreen([ 'home', null ]);
        }, Settings.resetTimeoutSec * 1000);
      }

      console.debug('resetTimeout(false)');
      return null;
    },
    null
  );

  useEffect(() => {
    const onTouch = () => resetTimeout(true);
    window.addEventListener('touchstart', onTouch);
    return () => window.removeEventListener('touchstart', onTouch);
  }, []);

  const onHome = useCallback(() => {
    setActiveScreen([ 'home', null ]);
  }, []);

  const onStartEn = useCallback(() => {
    setLang('en');
    setActiveScreen([ 'education', 1 ]);
  }, []);

  const onStartAr = useCallback(() => {
    setLang('ar');
    setActiveScreen([ 'education', 1 ]);
  }, []);

  const onBackEducation = useCallback(() => {
    if (!activeScreen[1]) return;
    if (activeScreen[1] > 1) {
      setActiveScreen([ 'education', activeScreen[1]-1 ]);
    } else {
      setActiveScreen([ 'home', null ]);
    }
  }, [ activeScreen ]);

  const onNextEducation = useCallback(() => {
    if (!activeScreen[1]) return;
    if (activeScreen[1] === LAST_EDUCATION_ID) {
      setActiveScreen([ 'quiz', null ]);
    } else {
      setActiveScreen([ 'education', activeScreen[1]+1 ]);
    }
  }, [ activeScreen ]);

  const onBackQuiz = useCallback(() => {
    setActiveScreen([ 'education', LAST_EDUCATION_ID ]);
  }, [])

  const onNextQuiz = useCallback(() => {
    setActiveScreen([ 'question', 1 ]);
  }, [])

  const onBackQuestion = useCallback(() => {
    if (!activeScreen[1]) return;
    if (activeScreen[1] > 1) {
      setActiveScreen([ 'question', activeScreen[1]-1 ]);
    } else {
      setActiveScreen([ 'quiz', null ]);
    }
  }, [ activeScreen ]);

  const onNextQuestion = useCallback(() => {
    if (!activeScreen[1]) return;
    if (activeScreen[1] === LAST_QUESTION_ID) {
      setActiveScreen([ 'complete', null ]);
    } else {
      setActiveScreen([ 'question', activeScreen[1]+1 ]);
    }
  }, [ activeScreen ]);

  const fullscreenActivator = useMemo(() => ( <FullscreenActivator /> ), []);

  let theScreen = null;

  if (activeScreen[0] === 'home') {
    theScreen = ( <Home onStartEn={onStartEn} onStartAr={onStartAr} /> );
  } else if (activeScreen[0] === 'education' && activeScreen[1]) {
    theScreen = (
      <Education
        lang={lang}
        id={activeScreen[1]}
        onHome={onHome}
        onBack={onBackEducation}
        onNext={onNextEducation}
      />
    );
  } else if (activeScreen[0] === 'quiz') {
    theScreen = (
      <Quiz
        lang={lang}
        onHome={onHome}
        onBack={onBackQuiz}
        onNext={onNextQuiz}
      />
    );
  } else if (activeScreen[0] === 'question' && activeScreen[1]) {
    theScreen = (
      <Question
        lang={lang}
        id={activeScreen[1]}
        onHome={onHome}
        onBack={onBackQuestion}
        onNext={onNextQuestion}
      />
    );
  } else if (activeScreen[0] === 'complete') {
    theScreen = (
      <Complete
        lang={lang}
        onHome={onHome}
      />
    );
  }

  return (
    <div className={styles.container}>
      {fullscreenActivator}
      {theScreen}
    </div>
  );
}
