import img0 from '../images/Progress-Horizontal-0.svg';
import img100 from '../images/Progress-Horizontal-100.svg';
import styles from './footer-progress-bar.module.css';

interface FooterProgressBarProps {
  progress: number
}

export default function FooterProgressBar ({
  progress
}: FooterProgressBarProps) {
  return (
    <div className={styles.container}>
      <img
        src={img0}
        className={styles.background}
        alt=""
      />
      <img
        src={img100}
        className={styles.foreground}
        style={{ width: `${progress*100}%` }}
        alt=""
      />
    </div>
  );
}
