import styles from './fullscreen-activator.module.css';

export default function FullscreenActivator () {
  const onFullscreenPress = () => {
    console.log('onFullscreenPress');

    if (!('requestFullscreen' in document.documentElement)) {
      console.error('document.documentElement.requestFullscreen() not available!');
      return;
    }

    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  return (
    <div className={styles.container} onClick={onFullscreenPress}>
      &nbsp;
    </div>
  );

}
