import beginIcon from '../images/Begin-Icon.svg';
import badge from '../images/Badge.svg';
import styles from './complete.module.css';

import Footer from '../components/footer';
import Content from '../content/Complete.json';


interface CompleteProps {
  lang: 'en' | 'ar'
  onHome: (() => void)
}

export default function Complete ({
  lang,
  onHome
}: CompleteProps) {
  const content = Content[lang]

  let beginIconStyle = styles.beginIcon;
  // let buttonStyle = styles.button;
  let textContainerStyle = styles.textContainer;

  if (lang === 'ar') {
    beginIconStyle = styles.beginIconAr;
    // buttonStyle = styles.buttonAr;
    textContainerStyle = styles.textContainerAr;
  }

  let text = !content.text ? null : (
    <span className={styles.text}>{content.text}</span>
  );

  return (
    <div
      className={styles.container}
    >
      <div className={textContainerStyle}>
        <span className={styles.heading}>{content.heading}</span>
        <span className={styles.subheading}>{content.subheading}</span>
        {text}
        <div className={styles.buttonsContainer}>
          <div
            className={styles.button}
            onClick={onHome}
          >
            <span className={styles.buttonText}>{content.go}</span>
            <img src={beginIcon} className={beginIconStyle} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.badgeWrapper}>
        <img src={badge} className={styles.badge} alt="" />
      </div>
      <Footer lang={lang} onHome={onHome} progress={0} />
    </div>
  );
}
