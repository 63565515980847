import styles from './home.module.css';

import Footer from '../components/footer';
import Content from '../content/Home.json';

interface HomeProps {
  onStartAr: (() => void),
  onStartEn: (() => void)
}

export default function Home({
  onStartAr,
  onStartEn
}: HomeProps) {
  return (
    <div className={styles.container}>
      <video
        className={styles.background}
        src={`/assets/${Content.backgroundVideo}`}
        autoPlay={true}
        controls={false}
        loop={true}
        playsInline={true}
        muted={true}
        crossOrigin='anonymous'
      />
      <div className={styles.textContainer}>
        <span className={styles.heading}>{Content.en.heading}</span>
        <span className={styles.heading}>{Content.ar.heading}</span>
      </div>
      <div className={styles.textContainer}>
        <span className={styles.subheading}>{Content.en.subheading}</span>
        <span className={styles.subheading}>{Content.ar.subheading}</span>
      </div>
      <div className={styles.buttonsContainer}>
        <div
          className={styles.button}
          onClick={onStartEn}
        >
          <span className={styles.buttonText}>{Content.en.go}</span>
        </div>
        <div
          className={styles.button}
          onClick={onStartAr}
        >
          <span className={styles.buttonTextAr}>{Content.ar.go}</span>
          </div>

      </div>
      <Footer lang="en" progress={0} />
    </div>
  );
}
