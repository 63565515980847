import { useCallback, useState } from 'react';

// import beginIcon from '../images/Begin-Icon.svg';
import styles from './question.module.css';

import Footer from '../components/footer';
import QuestionAnswerButton from '../components/question-answer-button';
import QuestionAnswerImage from '../components/question-answer-image';
import Settings from '../content/Settings.json';
import QuizContent from '../content/Quiz.json';
import _Content from '../content/Questions.json';

interface  QuestionContentData {
  progress: number,
  image?: string,
  correctAnswer: number,
  en: {
    question: string,
    answers: (string | { image: string } | { icon: string, text: string })[]
  },
  ar: {
    question: string,
    answers: (string | { image: string } | { icon: string, text: string })[]
  },
}

interface QuestionProps {
  id: number
  lang: 'en' | 'ar'
  onHome: (() => void)
  onBack: (() => void)
  onNext: (() => void)
}

const Content = _Content as Record<string,  QuestionContentData>;

export default function Question ({
  id,
  lang,
  onHome: _onHome,
  onBack: _onBack,
  onNext: _onNext
}: QuestionProps) {
  const [ answerGiven, setAnswerGiven ] = useState<null | number>(null);

  const onHome = useCallback(() => {
    _onHome();
    setAnswerGiven(null);
  }, [ _onHome ]);

  const onBack = useCallback(() => {
    _onBack();
    setAnswerGiven(null);
  }, [ _onBack ]);

  const onNext = useCallback(() => {
    _onNext();
    setAnswerGiven(null);
  }, [ _onNext ]);

  const content = Content[id]
  const langContent = content[lang]

  let imageOrVideo = !content.image ? null : (
    <img src={`/assets/${content.image}`} className={styles.imageOrVideo} alt="" />
  );

  const answers = langContent.answers.map((answer, i) => {
    if ('string' == typeof answer || 'icon' in answer) {
      let answerIcon = 'string' == typeof answer ? undefined : answer?.icon;
      let answerText = 'string' == typeof answer ? answer : answer.text;
      if (content.correctAnswer === i) {
        return (
          <QuestionAnswerButton
            key={i}
            number={i}
            text={answerText}
            icon={answerIcon}
            responseCorrect={QuizContent[lang].correct}
            answerGiven={answerGiven}
            setAnswerGiven={setAnswerGiven}
            onNext={onNext}
            lang={lang}
          />
        );
      }
      return (
        <QuestionAnswerButton
          key={i}
          number={i}
          text={answerText}
          icon={answerIcon}
          responseIncorrect={QuizContent[lang].incorrect}
          answerGiven={answerGiven}
          setAnswerGiven={setAnswerGiven}
          lang={lang}
        />
      )
    } else if ('image' in answer) {
      if (content.correctAnswer === i) {
        return (
          <QuestionAnswerImage
            key={i}
            number={i}
            image={answer.image}
            responseCorrect={QuizContent[lang].correct}
            answerGiven={answerGiven}
            setAnswerGiven={setAnswerGiven}
            onNext={onNext}
            lang={lang}
          />
        );
      }
      return (
        <QuestionAnswerImage
          key={i}
          number={i}
          image={answer.image}
          responseIncorrect={QuizContent[lang].incorrect}
          answerGiven={answerGiven}
          setAnswerGiven={setAnswerGiven}
          lang={lang}
        />
      )
    } else {
      throw new Error(`Invalid answer structure: ${JSON.stringify(answer)}`);
    }
  });

  let mainStyle = styles.mainContent;
  let answersStyle = styles.answers
  if ('object' == typeof langContent.answers[0] && 'image' in langContent.answers[0]) {
    answersStyle = styles.answersImages;
    mainStyle = styles.mainContentImages;
  } else if (answers.length === 2) {
    answersStyle = styles.answersShort;
  }

  let headingContainerStyle = styles.headingContainer;
  let headingStyle = styles.heading;
  if (Settings.altColor) {
    headingContainerStyle = styles.headingContainerAltColor;
    headingStyle = styles.headingAltColor;
  }

  return (
    <div className={styles.container}>
      <div className={headingContainerStyle}>
        <span className={headingStyle}>{langContent.question}</span>
      </div>
      <div className={mainStyle}>
        {imageOrVideo}
        <div className={answersStyle}>{answers}</div>
      </div>
      <Footer lang={lang} progress={content.progress} onHome={onHome} onBack={onBack} />
    </div>
  );
}
